import cellSmartService from '@/api/cellsmart.js';

export default {
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  // getToken() {
  //   const data = {
  //     username: 'cellsmartpos',
  //     password: 'GxntdzwfXwuLXtAwTBEn2@YwrcRAiReT'
  //   }
  //   return cellSmartService
  //     .Api().post(`apiToken`, data)
  //     .then(response => response.data)
  //     .catch((error) => { throw error })
  // },
  getRepair(id) {
    return cellSmartService
      .Api().get(`cspApi/public/encrypted/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  },
};
