<template>
  <div>
    <v-app-bar app elevation="0" color="white">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <div class="d-flex flex-row align-center">
        <v-img
          alt="Cellsmart Logo"
          class="shrink"
          contain
          src="@/assets/logo.png"
          height="50"
          width="50"
        />
      </div>
      <v-spacer></v-spacer>
      <div v-if="status && status.Store" class="text-caption">
        {{ status.Store.businessName }}
      </div>
      <v-spacer></v-spacer>
      <!-- <v-spacer></v-spacer>
      <v-btn rounded dark to="/status" color="warning">Check status</v-btn> -->
    </v-app-bar>
    <v-divider></v-divider>
    <v-container fluid>
      <v-card-text v-if="status" style="height: 100%">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5"> Repair </v-list-item-title>
            <v-list-item-subtitle>{{
              status.RepairDate | moment("LL LT")
            }}</v-list-item-subtitle>
            <v-list-item-subtitle
              >Order Number# {{ status.RepairDetailID }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-card-text>
          <v-row align="center">
            <v-col class="text-h2" cols="12">
              {{ status.repairStatus.RepairStatusName }}
            </v-col>
          </v-row>
          <v-chip color="green" text-color="white" v-if="status.IsClosed" small>Closed</v-chip>
        </v-card-text>

        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-barcode</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize"
              >Tracking Number</v-list-item-title
            >
            <v-list-item-subtitle>{{ id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize">{{
              status.Customer.CustomerName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              status.Customer.PhoneNumber
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="status.Customer.email">{{
              status.Customer.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
          <v-list-item-icon>
            <v-icon>mdi-storefront</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize">{{
              status.Store.businessName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              status.Store.address
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="status.Store.businessPhone">{{
              status.Store.businessPhone | phone
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-cellphone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize">{{
              status.ProductName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ status.IMEINumber }}</v-list-item-subtitle>
            
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize"
              >Balance Due</v-list-item-title
            >
            <v-list-item-subtitle>{{
              status.DueAmount | currency
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-row>
          <v-col cols="12">
            <div class="font-weight-black text-subtitle-1">Problem</div>
            <div v-if="status.Notes">{{ status.Notes }}</div>
            <div v-else>N/A</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import statusService from "@/modules/Status/service.js";
import { mapGetters } from "vuex";
const { validationMixin } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  name: "status-page",
  data() {
    return {
      loading: false,
      id: null,
      status: null,
      token: null,
    };
  },
  computed: {
    ...mapGetters("global", ["getContactNumber"]),
  },
  created() {
    if (!this.$route.params || !this.$route.params.id)
      return this.$router.push("/");
    this.id = this.$route.params.id.toUpperCase();
    this.loadStatus();
  },
  methods: {
    async loadStatus() {
      statusService
        .getRepair(this.id)
        .then((response) => {
          console.log("getRepair", response);
          this.status = response.data;
        })
        .catch((error) => {
          console.log("error", error);
          this.$swal(
            "Track Repair",
            "Repair not found please check tracking number and try again",
            "error"
          ).then(() => {
            this.$router.push("/");
          });
        });
    },
    reset() {
      this.status = null;
      this.simcard = null;
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            timer: 3000,
          })
          .fire({
            type: "error",
            title: "Require fields missing",
          });
        return false;
      }
      this.loading = true;
      return statusService
        .checkStatus(this.simcard)
        .then((response) => {
          this.loading = false;
          this.status = response.data;
          console.log("result", response);
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.$swal(
            "Sim Status",
            "Invalid sim number please check sim number and try again.",
            "error"
          );
        });
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      simcard: {
        required,
        minLength: minLength(14),
      },
    };
  },
};
</script>

<style scoped>
.card-labels {
  display: block;
}

.touch-view-container {
  height: calc(100vh - 105px);
  width: 100%;
  overflow: auto;
}
.noscroll {
  overflow-y: scroll;
}
div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.selected {
  background: #0f0c60 !important;
}
.cong {
  color: #0f0c60;
}
</style>

